import { t } from 'i18next';
import React, { FC, useState, useContext } from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { instanceApi } from '../../../api/instanceApi';
import { Account } from '../../../interface/account';
import { Environment } from '../../../models/instance';
import { ToasterContext } from '../../../pages/App/App';
import CustomModal from '../../CustomModal/CustomModal';
import AddUrlApplication from './CreateInstanceUrl/AddUrlApplication';
import { urlNames, tradToaster, tradCreationForm } from './InstanceKeyTranslation';

interface InstancesFormProps {
    show: boolean;
    setShow: Function;
    refreshInstance: Function;
    step: 1 | 2;
    setStep: Function;
    accounts: Array<Account>;
}

const hostingValue: Array<{ label: string; value: number; }> = [{ label: t('saas'), value: 1 }, { label: t('onPremise'), value: 2 }];
const instanceStatus: Array<{ label: string; value: number; }> = [{ label: t('instances_start'), value: 1 }, { label: t('instances_stop'), value: 2 }, { label: t('instances_archived'), value: 3 }];
const instanceType: Array<{ label: string; value: string; }> = [{ label: t('instance.instance_type.demo'), value: Environment.Demonstration }, { label: t('instance.instance_type.integration'), value: Environment.Integration }, { label: t('instance.instance_type.preprod'), value: Environment.Preproduction }, { label: t('instance.instance_type.production'), value: Environment.Production }]

const CreateInstancesModal: FC<InstancesFormProps> = (props) => {
    const { tradToasterSuccess, tradToasterSuccessCreate, tradToasterError, tradToasterCreationError, tradToasterEmptyField } = tradToaster();
    const { tradCreationFormNextStep, tradCreationFormValidation, tradCreationFormTitle, tradCreationFormAddApp, tradCreationFormCancelAdd, tradCreationFormPreviousStep, tradCreationFormChooseAccountName, tradCreationFormChooseAccount, tradCreationFormInstanceName, tradCreationFormHostingType, tradCreationFormChooseState, tradCreationFormChooseInstanceType } = tradCreationForm();
    const { show, setShow, setStep, step, accounts, refreshInstance } = props;
    const { pushToast } = useContext(ToasterContext);
    const [instanceAccountNameValue, setInstanceAccountNameValue] = useState(0);
    const [teiaCoreUrlValue, setTeiaCoreUrlValue] = useState('');
    const [digitalTwinUrlValue, setDigitalTwinUrlValue] = useState('');
    const [teiaEngineUrlValue, setTeiaEngineUrlValue] = useState('');
    const [teiaConnectorUrlValue, setTeiaConnectorUrlValue] = useState('');
    const [teiaWorkflowUrlValue, setTeiaWorkflowUrlValue] = useState('');
    const [instanceValue, setInstanceValue] = useState('');
    const [hostingValueType, setHostingValueType] = useState(0);
    const [instanceStatusValue, setInstanceStatusValue] = useState(0);
    const [environment, setEnvironment] = useState('');

    const closeModal = () => {
        setEnvironment('');
        setInstanceStatusValue(0);
        setHostingValueType(0);
        setStep(1);
        setShow(false);
    }
    const onChangeAccountName = (newValue: SingleValue<{ label: string; value: number; }>) => {
        setInstanceAccountNameValue(newValue?.value as number);
    }
    const onChangeUrl = (event, name) => {
        name(event.target.value);
    }

    const optionAccount = accounts?.map((account) => ({ label: account.name, value: account.id }));
    const hostingTypeButton = hostingValue.map((host) => {
        return <Button className={hostingValueType === host.value ? 'active' : ''} value={host.value} key={host.value} variant="secondary" onClick={() => setHostingValueType(host.value)}>{host.label}</Button>
    })
    const instanceStatusButton = instanceStatus.map((status) => {
        return <Button className={instanceStatusValue === status.value ? 'active' : ''} value={status.value} key={status.value} variant="secondary" onClick={() => setInstanceStatusValue(status.value)}>{status.label}</Button>
    })
    const instanceTypeButton = instanceType.map((type) => {
        return <Button className={environment === type.value ? 'active' : ''} value={type.value} key={type.value} variant="secondary" onClick={() => setEnvironment(type.value)}>{type.label}</Button>
    })
    function ErrorHandling(type: any, title: string, message: string) {
        pushToast({
            type: type,
            title: title,
            message: message,
            autoHide: true
        });
    }
    function getFunctionName(name) {
        switch (name) {
            case urlNames.teiaCore:
                return setTeiaCoreUrlValue;
            case urlNames.teiaTwin:
                return setDigitalTwinUrlValue;
            case urlNames.teiaEngine:
                return setTeiaEngineUrlValue;
            case urlNames.teiaConnector:
                return setTeiaConnectorUrlValue;
            case urlNames.teiaWorkflow:
                return setTeiaWorkflowUrlValue;
            default:
                return null;
        }
    }
    const onSubmitInstance = async (instance) => {
        try {
            const result = await instanceApi.create(instance);
            if (result?.status === 200) {
                ErrorHandling(
                    'success',
                    tradToasterSuccess,
                    tradToasterSuccessCreate);
            } else if (!result) {
                ErrorHandling(
                    'error',
                    tradToasterError,
                    tradToasterCreationError);
            }
            refreshInstance();
            closeModal();
        } catch (error) {
            ErrorHandling(
                'error',
                tradToasterError,
                tradToasterEmptyField);
        }
    }

    return (
        <CustomModal
            show={show}
            setShow={setShow}
            onAction={() => step === 1 ? setStep(2) : onSubmitInstance({ instanceAccountNameValue, hostingValueType, instanceStatusValue, instanceValue, teiaCoreUrlValue, digitalTwinUrlValue, teiaEngineUrlValue, teiaConnectorUrlValue, teiaWorkflowUrlValue, environment })}
            actionName={step === 1 ? tradCreationFormNextStep : tradCreationFormValidation}
            title={step === 1 ? step + '-' + tradCreationFormTitle : step + '-' + tradCreationFormAddApp}
            type="primary"
            cancelButton={step === 1 ? tradCreationFormCancelAdd : tradCreationFormPreviousStep}
            onActionCancel={step === 2 ? () => setStep(1) : () => closeModal()}>
            {step === 1 &&
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>{tradCreationFormChooseAccountName}</Form.Label>
                        <Select options={optionAccount} onChange={onChangeAccountName} placeholder={tradCreationFormChooseAccount} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{tradCreationFormInstanceName}</Form.Label>
                        <Form.Control type="text" onChange={(event) => onChangeUrl(event, setInstanceValue)} placeholder={tradCreationFormInstanceName} />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative z-0">
                        <Form.Label className="d-block">{tradCreationFormHostingType}</Form.Label>
                        <ButtonGroup>{hostingTypeButton}</ButtonGroup>
                    </Form.Group>
                    <Form.Group className='mb-3 position-relative z-0'>
                        <Form.Label className="d-block">{tradCreationFormChooseState}</Form.Label>
                        <ButtonGroup>{instanceStatusButton}</ButtonGroup>
                    </Form.Group>
                    <Form.Group className='position-relative z-0'>
                        <Form.Label className="d-block">{tradCreationFormChooseInstanceType}</Form.Label>
                        <ButtonGroup>{instanceTypeButton}</ButtonGroup>
                    </Form.Group>
                </>
            }
            {step === 2 &&
                [urlNames.teiaCore,
                urlNames.teiaTwin,
                urlNames.teiaEngine,
                urlNames.teiaConnector,
                urlNames.teiaWorkflow].map(name => (
                    AddUrlApplication(t(`instance.name_app.${name}`), (event) => onChangeUrl(event, getFunctionName(name)))
                ))
            }
        </CustomModal >
    )
}
export default CreateInstancesModal;
