import React, { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { Button, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { Account } from '../../../interface/account';
import { accountApi } from '../../../api/accountApi';
import CreateAccountModal from '../../Modals/Account/CreateAccountModal';
import UpdateAccountModal from '../../Modals/Account/UpdateAccountModal';
import DeleteAccountModal from '../../Modals/Account/DeleteAccountModal';
import { CellProps } from 'react-table';
import { perPageOptions } from '../../../utils/files-utils';
import { tradHeader, tradTableForm } from './AccountTableKeyTranslation';

const ActionType = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3
};

const AccountTable: FC = () => {
  const [showModalCustomers, setShowModalCustomers] = useState<boolean>(false);
  const [accountsSource, setAccountsSource] = useState<Array<Account>>([]);
  const [actionType, setActionType] = useState<any>(ActionType.CREATE);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>({
    id: 0,
    name: '',
    principalContact: '',
    principalEmail: '',
    creationDate: '',
    creationLocaleDate: ''
  });
  const [filterValue, setFilterValue] = useState('');
  const { tradHeaderAccount, tradHeaderPrimaryContact, tradHeaderEmailContact, tradHeaderDate, tradHeaderLabel, tradHeaderUpdate, tradHeaderDelete } = tradHeader();
  const { tradTableFormTitle, tradTableFormCsv, tradTableFormAddCustomers, tradTableFormPlaceholder, tradTableFormSortPage, tradTableFormDate, tradTableFormSearch } = tradTableForm();

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await accountApi.getAll();
      setAccountsSource(result ?? []);
    };
    fetchAccounts();
  }, [refreshData]);

  const createAccount = () => {
    setActionType(ActionType.CREATE);
    setShowModalCustomers(true);
  };

  const updateAccount = (account) => {
    setActionType(ActionType.UPDATE);
    setAccount(account);
    setShowModalCustomers(true);
  };

  const deleteAccount = async (account) => {
    setActionType(ActionType.DELETE);
    setAccount(account);
    setShowModalCustomers(true);
  };

  const handleFilter = (event: ChangeEvent) => {
    setFilterValue((event.target as HTMLInputElement).value);
  };

  const filterData = accountsSource.filter((account) => account.name.toLowerCase().includes(filterValue.toLowerCase()));

  const columnsDefinition = useMemo(
    () => [
      {
        Header: tradHeaderAccount,
        accessor: 'name',
        Cell: ({ cell }: CellProps<Account>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{cell.row.original.name}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '100px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.name}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderPrimaryContact,
        accessor: 'principalContact',
        Cell: ({ cell }: CellProps<Account>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{cell.row.original.principalContact}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '100px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.principalContact}
            </span>
          </OverlayTrigger>)
      },
      {
        Header: tradHeaderEmailContact,
        accessor: 'principalEmail'
      },
      {
        Header: tradHeaderDate,
        accessor: 'creationLocaleDate'
      },
      {
        Header: tradHeaderLabel,
        accessor: '',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<any>) => (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="primary" size="sm">
                {tradHeaderLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    updateAccount(cell.row.original);
                  }}>
                  {tradHeaderUpdate}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    deleteAccount(cell.row.original);
                  }}>
                  {tradHeaderDelete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    ],
    [accountsSource]
  );

  const downloadButton = document.getElementById('downloadButton');

  if (downloadButton) {
    downloadButton.addEventListener('click', () => {
      accountApi.exportAccounts();
    });
  }

  return (
    <>
      {actionType === ActionType.CREATE && (
        <CreateAccountModal
          show={showModalCustomers}
          setShow={setShowModalCustomers}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}
      {actionType === ActionType.UPDATE && (
        <UpdateAccountModal
          account={account}
          setAccount={setAccount}
          show={showModalCustomers}
          setShow={setShowModalCustomers}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}
      {actionType === ActionType.DELETE && (
        <DeleteAccountModal
          account={account}
          show={showModalCustomers}
          setShow={setShowModalCustomers}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}

      <Card className="card-table mb-4">
        <Card.Header className="p-4 d-flex justify-content-between align-items-center">
          <h4 className="card-heading">{tradTableFormTitle}</h4>
          <div>
            <Button variant="outline-primary" id="downloadButton">
              {tradTableFormCsv}
            </Button>
            <span className="m-2" />
            <Button variant="outline-primary" onClick={() => { createAccount(); }}>
              {' '}{tradTableFormAddCustomers}{' '}
            </Button>
          </div>
        </Card.Header>
        <Card.Body style={{ tableLayout: 'fixed' }}>
          <DataTable
            columns={columnsDefinition}
            items={filterData}
            placeholder={tradTableFormPlaceholder}
            labelPage={tradTableFormSortPage}
            lang={tradTableFormDate}
            perPageOptions={perPageOptions}
            genericFilterValue={filterValue}>
            <GenericFilter
              placeholder={tradTableFormSearch}
              handleChange={handleFilter} />
          </DataTable>
        </Card.Body>
      </Card>
    </>
  );
};

export default AccountTable;
